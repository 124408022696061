import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Button from "../../../components/custom-widgets/button";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";

const PosAndInventorySystem = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/pos-and-inventory-systems/hero-pos-inventory-systems-102623-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "pos-and-inventory-systems-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman using a tablet in a warehouse.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Easily Monitor Inventory and Manage Sales"
          }
        },
        {
          id: 2,
          button: {
            id: "heartland-hero-btn",
            class: "btn-white",
            text: "Get Started Today",
            url: "https://go.heartland.us/wafd#form",
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-services",
      title: "Business Services"
    },
    {
      id: 3,
      title: "Point of Sale and Inventory Systems",
      active: true
    }
  ];

  const SEOData = {
    title: "Point of Sale (POS) and Inventory Systems",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Point of Sale (POS) and Inventory Systems"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Need a Point of Sale (POS) and inventory system to easily monitor inventory and manage sales? Find out how WaFd Bank partners with Heartland to provide POS payment systems."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/pos-and-inventory-systems"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-pos-inventory-systems-102623.jpg"
      }
    ]
  };

  const HeartlandGetStartedButton = (id) => (
    <a
      href="https://go.heartland.us/wafd#form"
      id={id}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-link no-min-width w-100 w-sm-auto"
    >
      Get Started
    </a>
  );

  const heartlandRetailListData = {
    accountName: "heartland-retail-list",
    items: [
      {
        id: 1,
        text: "Mobile point of sale system"
      },
      {
        id: 2,
        text: "Customer relationship management"
      },
      {
        id: 3,
        text: "Inventory management tools"
      },
      {
        id: 4,
        text: "Purchasing and receiving"
      },
      {
        id: 5,
        text: "Advanced reporting"
      },
      {
        id: 6,
        text: "Omnichannel integrations"
      },
      {
        id: 7,
        text: "Sleek hardware options"
      },
      {
        id: 8,
        text: "24/7 customer support"
      }
    ]
  };

  const HeartLandRetailContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold">Heartland Retail</h2>
        <p>
          Welcome to our customer-centric world! Whether in-store, online, or on the road, our solutions offer a
          seamless brand experience. Speed up checkout with easy customer lookup, rule-based promos, and quick-sale
          buttons for popular items. Plus, our custom reporting engine provides real-time data for better business
          decisions.
        </p>
        <List {...heartlandRetailListData} />
        <HeartlandGetStartedButton id="heartland-retail-get-started-cta" />
      </>
    );
  };

  const heartlandRegisterListData = {
    accountName: "heartland-register-list",
    items: [
      {
        id: 1,
        text: "Accept online orders from anywhere"
      },
      {
        id: 2,
        text: "Create better checkout experiences"
      },
      {
        id: 3,
        text: "Build smarter employee schedules"
      },
      {
        id: 4,
        text: "Eliminate inventory shortages"
      },
      {
        id: 5,
        text: "Simplify accounting tasks"
      },
      {
        id: 6,
        text: "Take advantage of 24/7 support"
      }
    ]
  };

  const HeartLandRegisterContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold">Heartland Register</h2>
        <p>
          Meet Register, the modern POS system that revolutionizes traditional cash registers. With online ordering,
          inventory tracking, and detailed reporting, Register offers a comprehensive solution. It combines a point of
          sale, payment terminal, and online ordering system for quick scanning, email receipts, and seamless payments.
          Simplifying operations and boosting profitability, Register is ideal for small business owners, whether you
          sell pizza, pedicures, or paper goods.
        </p>
        <List {...heartlandRegisterListData} />
        <HeartlandGetStartedButton id="heartland-register-get-started-cta" />
      </>
    );
  };

  const heartlandRestaurantListData = {
    accountName: "heartland-restaurant-list",
    items: [
      {
        id: 1,
        text: "Mobile POS system"
      },
      {
        id: 2,
        text: "Online ordering"
      },
      {
        id: 3,
        text: "Kitchen management"
      },
      {
        id: 4,
        text: "Employee management"
      },
      {
        id: 5,
        text: "Order balancing"
      },
      {
        id: 6,
        text: "Detailed reporting"
      },
      {
        id: 7,
        text: "Scan to pay"
      },
      {
        id: 8,
        text: "75+ integrations for other software your restaurant may use"
      },
      {
        id: 9,
        text: "Real-time menu updates"
      },
      {
        id: 10,
        text: "Customer loyalty"
      },
      {
        id: 11,
        text: "Tip management"
      },
      {
        id: 12,
        text: "Take advantage of 24/7 support, based in the state of Indiana"
      }
    ]
  };

  const HeartLandRestaurantContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold">Heartland Restaurant</h2>
        <p>
          Give your customers (and your employees) a great experience to keep them coming back for more and help your
          employees be more efficient than ever.
        </p>
        <List {...heartlandRestaurantListData} />
        <HeartlandGetStartedButton id="heartland-restaurant-get-started-cta" />
      </>
    );
  };

  const pointOfSaleListData1 = {
    greenCircleCheckmark: true,
    accountName: "point-of-sale-list",
    items: [
      {
        id: 1,
        text: `<span class="font-weight-bold">Accept popular, convenient payment types:</span> Let customers pay with
            EMV chip cards, contactless payment methods, debit cards, Apple Pay, Google Pay, gift cards and more.`
      },
      {
        id: 2,
        text: `<span class="font-weight-bold">Ditch outdated POS hardware:</span> Trade old, clunky tech for compact
        credit card readers, barcode scanners, sleek cash drawers, iPads and mobile POS terminals.`
      },
      {
        id: 3,
        text: `<span class="font-weight-bold">Sell everywhere with omnichannel POS features:</span> Give your customers
        a consistent and seamless checkout experience by connecting your POS and eCommerce online store.`
      }
    ]
  };
  const pointOfSaleListData2 = {
    greenCircleCheckmark: true,
    accountName: "point-of-sale-list",
    items: [
      {
        id: 4,
        text: `<span class="font-weight-bold">Turn more tables with 5-star functionality:</span> Speed up orders,
        operations and checkout with user-friendly real-time software, mobile ordering and fast payment processing.`
      },
      {
        id: 5,
        text: `<span class="font-weight-bold">Hit the road without missing a beat (or sale):</span> Go mobile with
        cloud POS solutions, whether you're testing a pop-up shop or keeping an eye on your store from a buying
        trip.`
      },
      {
        id: 6,
        text: `<span class="font-weight-bold">Grow and scale your business confidently:</span> Choose a solution that
        scales with you. If you outgrow your Heartland POS system, we'll help you seamlessly upgrade to a better
        fit.`
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Point of Sale and Inventory Systems</h1>
        <h3>
          Effortlessly monitor inventory and sales for businesses of all sizes. Created by retail experts with over 100
          years of combined experience, this software gives merchants better control over sales and profitability by
          providing real-time data to everyone, from the CEO to the store floor.
        </h3>
        <div className="row">
          <div className="col col-12 col-md-6">
            <List {...pointOfSaleListData1} />
          </div>
          <div className="col col-12 col-md-6">
            <List {...pointOfSaleListData2} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-12 col-md-4">
            <Button
              id="get-started-cta"
              text="Get Started"
              url="https://go.heartland.us/wafd#form"
              externalLink={true}
              target="blank"
              containerClass="w-100"
              class="btn-primary w-100"
              showIcon={false}
            />
          </div>
        </div>
      </section>

      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              src="../../../images/thumbnail-heartland-retail-050824.jpg"
              alt="Heartland retail checkout with mobile phone, card reader, and credit card."
            />
          ),
          content: <HeartLandRetailContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              src="../../../images/thumbnail-heartland-register-050824.jpg"
              alt="A heartland register on a shop counter."
            />
          ),
          content: <HeartLandRegisterContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              src="../../../images/thumbnail-heartland-restaurant-050824.jpg"
              alt="CustomStore employee checking out a customer using Heartland software."
            />
          ),
          content: <HeartLandRestaurantContent />
        }}
      </TwoColumnSectionWithImage>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default PosAndInventorySystem;
